tr td .TableSelectCell-checkbox-720 {
  padding: 0;
}

.ck-content.ck-editor__editable {
  min-height: 300px;
}

.highcharts-axis-labels text, .highcharts-axis text tspan, .ct-chart .ct-label {
  font-size: 14px !important
}
